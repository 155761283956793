import React, { useEffect } from 'react';
import { Box, Icon, useBreakpoint } from '@palmetto/palmetto-components';
import { Link, NavLink } from 'react-router-dom';
import configuration from './config';

const NavItem = ({
  className,
  item,
  level,
  handleClick,
}: {
  className?: string;
  item: any;
  level: number;
  handleClick: () => void;
}) => {
  return (
    <Box childGap="2xs" className={className} fontSize="sm" fontWeight="medium">
      {item.path && (
        <NavLink className="nav-item" title={item.title} to={item.path} onClick={handleClick}>
          {item.name}
        </NavLink>
      )}
      {!item.path && <Box className="nav-folder" as="span" title={item.title}>{item.name}</Box>}
      <Box padding="0 0 0 sm">
        {item.children &&
          item.children.map((child: any) => (
            <NavItem item={child} level={level + 1} key={child.path || child.name} handleClick={handleClick} />
          ))}
      </Box>
    </Box>
  );
};

interface MainNavProps {
  onOpen?: () => void;
  onClose?: () => void;
}

const MainNav = ({ onOpen, onClose }: MainNavProps) => {
  const { isPhone, isTablet } = useBreakpoint();

  const isMobile = isPhone || isTablet;

  const [isOpen, setIsOpen] = React.useState(false);

  const { nav } = configuration;

  const toggleNav = () => {
    const prevState = isOpen;

    applyWrapperStyles();

    // Ensures wrapper styles are applied before the menu is toggled
    setTimeout(() => {
      setIsOpen(!prevState);
    });
  };

  const open = () => {
    if (typeof onOpen === 'function') {
      onOpen();
    } else {
      toggleNav();
    }
  };

  const close = () => {
    if (typeof onClose === 'function') {
      onClose();
    } else {
      toggleNav();
    }
  };

  const applyWrapperStyles = (set = true) => {
    const applyClass = (el: any, className: string) => el.classList[set ? 'add' : 'remove'](className);

    applyClass(document.querySelector('body'), 'overflow-hidden');
  };

  useEffect(() => {
    if (!isOpen) {
      applyWrapperStyles(false);
    }
  }, [isOpen]);

  const items = nav.map((item: any) => <NavItem key={item.path || item.name} item={item} level={0} handleClick={close} />);

  return (
    <Box
      as="nav"
      fontSize={{ base: 'sm', desktop: 'md' }}
      direction={{ base: 'row', desktop: 'column' }}
      width={{ base: '100', desktop: '20' }}
      style={{ textTransform: 'capitalize' }}
      background="white"
      borderWidth={{ base: '0 0 xs 0', desktop: '0 xs 0 0' }}
      borderColor="grey-100"
      alignItems={{ base: 'center', desktop: 'flex-start' }}
      overflow={{ desktop: 'auto' }}
      className="menu"
    >
      {isMobile ? (
        <>
          <Box padding="md lg" height="61px">
            <Box
              as="button"
              type="button"
              padding="2xs"
              background="transparent"
              borderWidth="0"
              hover={{ color: 'grey-700' }}
              onClick={isOpen ? close : open}
            >
              <Icon name="menu" size="lg" />
            </Box>
          </Box>
          <Box
            aria-hidden={!isOpen}
            position="absolute"
            padding="md"
            width="100"
            overflow="auto"
            background="white"
            className="mobile-menu"
            style={{
              top: '62px',
              left: '0',
              bottom: '0',
              transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
              transition: ' transform 0.3s ease-in-out',
              zIndex: 'var(--size-z-index-drawer)',
            }}
            shadow={isMobile && isOpen ? 'md' : undefined}
          >
            {items}
          </Box>
        </>
      ) : (
        <>
          <Box padding="md">
            <Link to="/">
              <Box direction="row" alignItems="center">
                <img src="/palmetto-brand-logo.svg" alt="Palmetto" height="32" />
                <Box fontWeight="medium" color="success-400" margin="0 0 0 xs" fontSize="md">
                  DOCS
                </Box>
              </Box>
            </Link>
          </Box>
          <Box padding="sm" width="100">
            {items}
          </Box>
        </>
      )}
    </Box>
  );
};

export default MainNav;
