const configuration = {
  nav: [
    {
      name: 'Finance',
      title: 'Palmetto Finance',
      children: [
        {
          name: 'Getting Started',
          path: 'guides/finance/getting-started',
          title: 'Palmetto Finance Getting Started',
        },
        {
          name: 'API reference (prod)',
          path: 'api/finance',
          title: 'Palmetto Finance API reference (prod)',
        },
        {
          name: 'API reference (next)',
          path: 'api/finance-next',
          title: 'Palmetto Finance API reference (next)',
        },
        {
          name: 'Webhooks',
          path: 'guides/finance/webhooks',
          title: 'Palmetto Finance Webhooks',
        },
        {
          name: 'Organizations',
          path: 'guides/finance/organizations',
          title: 'Palmetto Finance How Organizations work',
        },
        {
          name: 'Users',
          path: 'guides/finance/users',
          title: 'Palmetto Finance How Users work',
        },
        {
          name: 'Environments',
          path: 'guides/finance/environments',
          title: 'Palmetto Finance Environments',
        },
        {
          name: 'Test Data',
          path: 'guides/finance/test-data',
          title: 'Palmetto Finance Test Data',
        },
      ],
    },
    {
      name: 'Energy Platform',
      title: 'Palmetto Energy',
      children: [
        {
          name: 'Overview',
          path: 'guides/project/overview',
          title: 'Overview',
        },
        {
          name: 'Getting Started',
          path: 'guides/project/getting-started',
          title: 'Palmetto Energy Getting Started',
        },
        {
          name: 'API reference (prod)',
          path: 'api/energy',
          title: 'Palmetto Energy API reference (prod)',
        },
        {
          name: 'API reference (next)',
          path: 'api/energy-next',
          title: 'Palmetto Energy API reference (next)',
        },
        {
          name: 'Project Lifecycle',
          path: 'guides/project/project-lifecycle',
          title: 'Palmetto Energy Project Lifecycle',
        },
        {
          name: 'Statuses and Stages',
          path: 'guides/project/statuses-stages',
          title: 'Palmetto Energy Project Statuses and Stages',
        },
        {
          name: 'Subscriptions',
          path: 'guides/project/subscriptions',
          title: 'Palmetto Energy Subscriptions',
        },
        {
          name: "Project configurations",
          path: 'guides/project/project-configurations',
          title: 'Palmetto Energy Project Configurations',
        },
        {
          name: 'Environments',
          path: 'guides/project/environments',
          title: 'Palmetto Energy Environments',
        },
        {
          name: 'FAQ',
          path: 'guides/project/faq',
          title: 'Palmetto Energy Frequently Asked Questions',
        },
      ],
    },
    {
      name: 'Energy Intelligence',
      title: 'Palmetto Energy Intelligence',
      children: [
        {
          name: 'Overview',
          path: 'guides/ei-api/overview',
          title: 'Energy Intelligence API Overview',
        },
        {
          name: 'API Reference',
          path: 'api/ei',
          title: 'Energy Intelligence API Reference',
        },
      ],
    },
    {
      name: 'Examples',
      title: 'Examples',
      children: [
        {
          name: 'TypeScript Project Template',
          path: 'https://github.com/palmetto/palmetto-example-ts-energy-and-finance',
        },
      ]
    },
    // Disabling legacy docs for now
    // {
    //   name: 'Sales Platform (Legacy)',
    //   title: 'Documentation for the Sales Platform',
    //   path: 'guides/legacy/welcome',
    //   children: [
    //     {
    //       name: 'Getting Started',
    //       path: 'guides/legacy/getting-started',
    //     },
    //     {
    //       name: 'Use Cases',
    //       path: 'guides/legacy/use-cases',
    //     },
    //     {
    //       name: 'Login',
    //       path: 'guides/legacy/login',
    //     },
    //     {
    //       name: 'Contact',
    //       path: 'guides/legacy/contact',
    //     },
    //     {
    //       name: 'Opportunity',
    //       path: 'guides/legacy/opportunity',
    //     },
    //     {
    //       name: 'Proposal',
    //       path: 'guides/legacy/proposal',
    //     },
    //     {
    //       name: 'Financing',
    //       path: 'guides/legacy/financing',
    //     },
    //     {
    //       name: 'Lead',
    //       path: 'guides/legacy/lead',
    //     },
    //     {
    //       name: 'Sales Reps',
    //       path: 'guides/legacy/sales-reps',
    //     },
    //     {
    //       name: 'Order',
    //       path: 'guides/legacy/order',
    //     },
    //   ],
    // },
  ],
  auth0: {
    next: {
      clientId: 'm0BFtHloxUzoe3MOb65cRgg83daQD5Wh',
    },
    prod: {
      clientId: 'ZSk2P0PmJaygsqGffZCqjU5vT11GSLxT',
    },
  },
};
export default configuration;
