import { Box, Button, Heading } from '@palmetto/palmetto-components';
import PageHeader from './PageHeader';
import Product from './Product';


const Landing = () => {
  return (
    <Box padding='0' margin="0 auto" width="100" >
      <PageHeader
        titleColor='white'
        padding={{ base: 'xl', desktop: '4xl' }}
        className='hero'
        title="Supercharge clean energy with the Palmetto Platform"
        description='Our modular APIs make it easy to deliver clean energy solutions cheaply and accurately' />
      <Box padding={{ base: 'xl', desktop: ' xl 4xl 4xl 4xl' }} margin="0 auto" width="100" >
        <Heading as="h2">Services &amp; Products</Heading>
        <Box margin="lg 0 0 0" alignItems="center">
          <Product
            title="Energy Platform"
            description="A suite of APIs that make it simple to submit customers, designs and pricing to our transparent and auditable fulfillment pipeline."
            linkText="Start submitting solar projects"
            linkUrl="/guides/project/overview"
            imageUrl="/images/palmetto-logo.png"
            imageTitle="palmetto logo" />

          <Product
            title="Financing"
            description="APIs for accessing our financing platform, including the LightReach products. These APIs provide clean and intuitive ways to finance solar projects."
            linkText="Start financing solar projects"
            linkUrl="/guides/finance/getting-started"
            imageUrl="/images/finance-logo.png"
            imageTitle="finance logo" />

          <Product
            title="Energy Intelligence"
            description="Building Energy Modeling & Solar Simulations. Optimize building performance through data-driven predictions and what-if scenarios."
            linkText="Learn about Energy Intelligence"
            linkUrl="/guides/ei-api/overview"
            imageUrl="/images/EI-API-logo.svg"
            imageTitle="EI API logo" />

        </Box>
        <Heading as="h2">Next Steps</Heading>
        <Box direction="row" childGap="lg" margin="lg 0">
          <Button as="a" href='mailto:developer-support@palmetto.com?subject=Question' variant="primary" iconPrefix="mail" fullWidth isOutlined>
            Developer Support
          </Button>
          <Button as="a" href='mailto:developer-support@palmetto.com?subject=Access' variant="primary" iconPrefix="mail" fullWidth>
            Get Access
          </Button>
        </Box>
      </Box>
    </Box >
  );
};

export default Landing;
