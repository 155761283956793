import { Box, Icon } from '@palmetto/palmetto-components';
import { Link } from 'react-router-dom';

interface FooterProps {
  nextUrl?: string;
  nextText?: string;
  previousUrl?: string;
  previousText?: string;
}

const LinkButton = ({
  to,
  isPrevious,
  children,
}: {
  to: string;
  isPrevious?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Link to={to}>
      <Box
        radius="md"
        padding="xs sm"
        fontSize="sm"
        borderWidth="xs"
        borderColor="grey-200"
        display="inline-flex"
        direction="row"
        alignItems="center"
        childGap="2xs"
        hover={{ borderColor: 'grey-300' }}
      >
        {isPrevious && <Icon name="caret-sm-left" focusable="false" />}
        <div>{children}</div>
        {!isPrevious && <Icon name="caret-sm-right" focusable="false" />}
      </Box>
    </Link>
  );
};

const Footer = ({ nextUrl, nextText = 'Next', previousUrl, previousText = 'Previous' }: FooterProps) => {
  return (
    <Box
      display="flex"
      direction="row"
      childGap="xs"
      margin="lg 0 0 0"
      padding="lg 0 0 0"
      justifyContent={{ base: 'space-between' }}
      flex={{ base: 'auto', tablet: 'none' }}
      borderColor="grey-100"
      borderWidth="xs 0 0 0"
    >
      {previousUrl && (
        <LinkButton to={previousUrl} isPrevious>
          {previousText}
        </LinkButton>
      )}
      {nextUrl && <LinkButton to={nextUrl}>{nextText}</LinkButton>}
    </Box>
  );
};

export default Footer;
