import { Box, BoxProps, FontColor } from '@palmetto/palmetto-components';
import React, { CSSProperties, ReactNode } from 'react';

interface PageHeaderProps {
  title: string;
  description?: string;
  children?: ReactNode;
  className?: string;
  eyebrow?: ReactNode;
  style?: CSSProperties;
  padding?: BoxProps['padding'];
  margin?: BoxProps['margin'];
  titleColor?: FontColor;
}

const PageHeader = ({ title, description, children, className, eyebrow, style, padding, margin, titleColor, ...rest }: PageHeaderProps) => {
  return (
    <Box
      style={style}
      direction="row"
      justifyContent="space-between"
      alignItems={{ base: 'center' }}
      className={className}
      color="dark"
      padding={padding}
      margin={margin}
      {...rest}
    >
      <Box display="block" childGap="xs">
        {eyebrow && <Box display="block">{eyebrow}</Box>}

        {title && (
          <Box direction="row" justifyContent="flex-start" alignItems="baseline" >
            <Box
              as="h1"
              color={titleColor}
              fontSize={{
                base: 'xl',
                tablet: '2xl',
                desktop: '3xl',
              }}
              margin="0 0 xs 0"
            >
              {title}
            </Box>
          </Box>
        )}
        {description && (
          <Box
            fontSize={{
              base: 'sm',
              tablet: 'lg',
              desktop: 'xl',
            }}
          >
            {description}
          </Box>
        )}
      </Box>
      {children && <Box display="block">{children}</Box>}
    </Box>
  );
};

export default PageHeader;
