import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ResponsiveProvider } from "@palmetto/palmetto-components";
import "./index.css";
import App from "./App";
import Error from "./Error";
import Swagger from "./Swagger";
import Markdown from "./Markdown";
import Landing from "./Landing";
import { Helmet } from "react-helmet";
import config from "./config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/api/finance",
        element: (
          <Swagger specUrl="https://palmetto.finance/docs/json" title="Palmetto Finance API Reference (Prod)" clientId={config.auth0.prod.clientId} />
        ),
      },
      {
        path: "/api/finance-next",
        element: (
          <Swagger specUrl="https://next.palmetto.finance/docs/json" title="Palmetto Finance API Reference (Next)" clientId={config.auth0.next.clientId} />
        ),
      },
      {
        path: "/api/energy",
        element: (
          <Swagger specUrl="https://palmetto.energy/api/docs-json" title="Palmetto Energy API Reference (Prod)" clientId={config.auth0.prod.clientId} />
        ),
      },
      {
        path: "/api/energy-next",
        element: (
          <Swagger specUrl="https://next.palmetto.energy/api/docs-json" title="Palmetto Energy API Reference (Next)" clientId={config.auth0.next.clientId} />
        ),
      },
      {
        path: "/api/ei",
        element: (
          <Swagger specUrl="https://ei.palmetto.com/openapi.json" title="Energy Intelligence API Reference" clientId={config.auth0.prod.clientId} />
        ),
      },
      {
        path: "/*",
        element: <Markdown />,
      },
      {
        path: "/",
        element: <Landing />,
      },
    ],
  },
]);

root.render(
  <ResponsiveProvider>
    <Helmet>
      <title>Palmetto Docs</title>
    </Helmet>
    <RouterProvider router={router} />
  </ResponsiveProvider>
);
