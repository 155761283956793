import { Box, Heading, useBreakpoint } from '@palmetto/palmetto-components';

interface ProductProps {
  title: string;
  description: string;
  linkText: string;
  linkUrl: string;
  imageUrl: string;
  imageTitle: string;
}
const Product = ({ title, description, linkText, linkUrl, imageUrl, imageTitle, ...rest }: ProductProps) => {
  const { 
    isDesktop,
    isHd
  } = useBreakpoint();

  return <Box {...rest} direction="row"
    childGap="md"
    padding={{ base: 'lg' }}
    margin={{ base: '0 0 lg 0', tablet: '0 lg lg 0' }}
    alignItems="center"
    width={(isDesktop || isHd) ? '4xl' : '100%'}
    maxWidth="32rem"
    shadow="sm"
    borderWidth="xs"
    borderColor="grey-lightest"
    background='white'
    radius="md"
    hover={{
      shadow: 'lg'
    }}
    style={{ textDecoration: 'none' }}
    as='a'
    href={linkUrl}>

    <Box maxHeight="md" maxWidth="md" minHeight="md" minWidth="md" >
      <img src={imageUrl} alt={imageTitle} />
    </Box>

    <Box childGap="xs">
      <Heading style={{ marginTop: 0 }}>{title}</Heading>
      <Box>{description}</Box>
    </Box>
  </Box>;
}


export default Product;
