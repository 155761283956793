import React from 'react';
import '@palmetto/palmetto-components/dist/css/reset.css';
import '@palmetto/palmetto-components/dist/css/utilities.css';
import '@palmetto/palmetto-components/dist/css/variables.css';
import '@palmetto/palmetto-components/dist/css/index.css';
import '@palmetto/palmetto-components/dist/css/fonts.css';
import './App.css';
import { Box } from '@palmetto/palmetto-components';
import MainNav from './MainNav';
import Content from './Content';
import { ToastContainer } from '@palmetto/palmetto-components';

function App() {
  return (
    <Box background="grey-50" color="grey-600" direction={{ base: 'column', desktop: 'row' }} height="100dvh">
      <ToastContainer />
      <MainNav />
      <Content />
    </Box>
  );
}

export default App;
