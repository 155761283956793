import { Box } from "@palmetto/palmetto-components";
import { Helmet } from "react-helmet";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

export interface SwaggerProps {
  specUrl: string;
  title?: string;
  scopes?: string;
  clientId: string;
  audience?: string;
}

const Swagger = ({
  specUrl,
  title = "API Reference",
  audience = 'universal',
  scopes = 'openid offline_access profile email',
  clientId = ''
}: SwaggerProps) => {
  const oauth2RedirectUrl = `${window.location.protocol}//${window.location.host}/oauth2-redirect.html`;
  return (
    <>
      <Helmet>
        <title>Palmetto Docs - {title}</title>
      </Helmet>
      <Box>
        <SwaggerUI
          deepLinking
          url={specUrl}
          docExpansion="list"
          oauth2RedirectUrl={oauth2RedirectUrl}
          persistAuthorization
          onComplete={(system) => {
            system.initOAuth({
              clientId,
              scopeSeparator: ' ',
              additionalQueryStringParams: {
                audience,
                grant_type: 'authorization_code',
              },
              scopes,
              usePkceWithAuthorizationCodeGrant: true,
            });
          }}
        />
      </Box>
    </>
  );
};

export default Swagger;
