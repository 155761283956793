import { Box, Icon, FontSize, toast } from "@palmetto/palmetto-components";
import { useCallback } from "react";

const CopyLink = ({ id, children, fontSize = 'sm' }: { id: string, children: React.ReactNode, fontSize?: FontSize }) => {

  const handler = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      window.navigator.clipboard.writeText((e.currentTarget as any).href);
      toast.success('Link copied to clipboard');
    } catch (e) {
      toast.error('Failed to copy link to clipboard');
    }
  }, [])

  return (
    <a href={`#${id}`} className="anchor-link" onClick={handler}>
      {children}
      <Box className="anchor-link-icon" margin="0 0 0 xs" fontSize={fontSize} color="grey-300">
        <Icon name="link" />
      </Box>
    </a>
  )
}

export default CopyLink;

