import { Box } from '@palmetto/palmetto-components';
import { Outlet } from 'react-router-dom';

const Content = () => {
  return (
    <Box direction="column" overflow="auto" style={{ flex: '1' }}>
      <Outlet />
    </Box>
  );
};

export default Content;
