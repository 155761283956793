import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Alert } from '@palmetto/palmetto-components';
import './prism.theme.css';
import PreviousNext from './components/PreviousNext';
import CopyLink from './components/CopyLink';
import { Mermaid } from 'mdx-mermaid/lib/Mermaid'


const Markdown = () => {
  const { '*': splat } = useParams();

  const components = {
    /* Add custom components or overrides here */
    h1: (props: any) => (
      <Box
        as="h1"
        display="block"
        fontWeight="bold"
        fontSize={{ base: 'xl', desktop: '2xl' }}
        color="grey-700"
        borderColor="grey-200"
        borderWidth="0 0 xs 0"
        padding="0 0 sm 0"
        {...props}
      />
    ),

    h2: (props: any) => (
      <h2 {...props}>
        <CopyLink id={props.id} fontSize='md'>{props.children}</CopyLink>
      </h2>
    ),

    h3: (props: any) => (
      <h3 {...props}>
        <CopyLink id={props.id}>{props.children}</CopyLink>
      </h3>
    ),

    h4: (props: any) => (
      <h4 {...props}>
        <CopyLink id={props.id}>{props.children}</CopyLink>
      </h4>
    ),

    table: (props: any) => (
      <Box
        borderWidth="xs"
        borderColor="grey-100"
        margin="lg 0"
        overflow="auto"
        background="white"
        radius="md"
        display="block"
        maxWidth="100"
      >
        <table {...props} />
      </Box>
    ),

    th: (props: any) => (
      <Box
        as="th"
        display="table-cell"
        fontWeight="bold"
        fontSize="sm"
        textAlign="left"
        padding="sm xs sm md"
        background="grey-50"
        {...props}
      >
        {props.children}
      </Box>
    ),

    td: (props: any) => (
      <Box
        as="td"
        display="table-cell"
        fontSize="sm"
        textAlign="left"
        padding="sm xs sm md"
        borderColor="grey-100"
        borderWidth="xs 0 0 0"
        {...props}
      >
        {props.children}
      </Box>
    ),
    PreviousNext,
    Alert,
    Mermaid,
  };

  const { default: Component, frontmatter = {} } = require(`./${splat?.toLowerCase()}.mdx`);
  return (
    <>
      <Helmet>
        <title>{frontmatter.title || 'Palmetto Docs'}</title>
      </Helmet>
      <Box padding={{ base: 'lg', desktop: 'xl' }} margin="0 auto" width="100" maxWidth="5xl">
        <Component components={components} />
      </Box>
    </>
  );
};

export default Markdown;
